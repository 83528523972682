import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, IconButton, Link, Stack, Typography } from "@mui/material";
import { NAVY, social } from "./utils/Constants";

const Footer = () => {
    return (
        <Box mt={4} px={1}>
            <Stack display={{ md: 'none', xs: 'flex' }} alignItems="center">
                <Divider flexItem sx={{ my: 1 }} />
                <Typography color={NAVY} variant="body1" fontWeight="bold" textAlign="center" textTransform="uppercase">Stay Tuned</Typography>
                <Box display="flex" flexDirection="row">
                    {social.map((item, index) => (
                        <IconButton key={index} href={item.value} target="_blank" >
                            <FontAwesomeIcon icon={item.icon} size="xl" color={item.color} />
                        </IconButton>
                    ))}
                </Box>
            </Stack>

            <Divider sx={{ my: 1}} />
            <Typography variant="body1" align="center" color={NAVY} sx={{ mt: 1, fontWeight: "bold", textTransform: "uppercase" }}>
                Lycan Constructions, No.8/486, AVP Layout, 5th street, Gandhi nagar, Tiruppur - 641603.
            </Typography>
            <Typography variant="body2" align="center" color={NAVY} sx={{ mt: 1, fontWeight: "bold", textTransform: "uppercase" }}>
                © 2024 - All Rights reserved by Lycan Constructions
            </Typography>
            <Typography variant="subtitle2" align="center" color={NAVY} sx={{ mt: 1, mb: { xs: 12, md: 0 }, fontFamily: ['rockwell'] }}>
                Developed by <Link href="https://hyperyl.com" target="#blank" color="inherit">Hyperyl Solutions</Link>
            </Typography>
        </Box>
    );
}

export default Footer;